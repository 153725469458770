<template>
  <div class="main">
    <div class="layout">
      <h1 class="h1">{{detail.title}}</h1>
      <div class="author-info">
        <span class="name time">{{detail.addDate|formatDate('yyyy-MM-dd hh:mm')}}</span>
      </div>
      <div class="rich-text" v-html="detail.introduction"></div>
    </div>
  </div>
</template>
<script>
  import {getPromotionsDetail} from '../../api/home';

  export default {
    name: 'PromotionsDetail',
    data() {
      return {
        id: '',
        detail: {}
      };
    },
    created() {
      this.id = this.$route.params.id;
      this.getDetail();
    },
    methods: {
      getDetail() {
        getPromotionsDetail(this.id).then(res => {
          this.detail = res.value;
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .h1{margin:30px 0;text-align:center;font-size:48px;}
  .author-info{text-align:center;}
  .rich-text{margin-top:30px;}
</style>
